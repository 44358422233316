// Golbal declaration of window/analytics
declare global {
  interface Window {
    ChiliPiper: any;
  }
}

// Name of user action
export enum CHILIPIPER {
  demo = "/demo",
  router = "inbound_router",
  routerGeneric = "inbound_router_generic",
  routerHSForm = "inbound_router_demo_f",
  script = "https://js.chilipiper.com/marketing.js",
  name = "perpetua",
  bookingText = "Thanks! What time works best for a quick call?"
}

export const chiliPiperAdd = () => {
  let s = document.createElement("script");
  s.type = "text/javascript";
  s.src = CHILIPIPER.script;
  let c = document.getElementsByTagName("script")[0];
  c.parentNode.insertBefore(s, c);
};

export const chiliPiperSubmit = async (props: any) => {
  window?.ChiliPiper?.submit(CHILIPIPER.name, CHILIPIPER.router, {
    lead: {
      ...props
    }
  });
};

export const chiliPiperSubmitGeneric = async (props: any) => {
  window?.ChiliPiper?.submit(CHILIPIPER.name, CHILIPIPER.routerGeneric, {
    lead: {
      ...props
    }
  });
};

export const chiliPiperSubmitHSForm = async (props: any) => {
  window?.ChiliPiper?.submit(CHILIPIPER.name, CHILIPIPER.routerHSForm, {
    map: true,
    lead: {
      ...props
    }
  });
};
