import classNames from "classnames";
import React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input: React.FC<InputProps> = props => {
  const { className, ...rest } = props;
  return (
    <input
      {...rest}
      className={classNames(
        "bg-white rounded-lg border border-gray-3-light hover:border-gray-1  focus:border-indigo-1 focus:outline-none focus:ring-4 focus:ring-indigo-3-light placeholder-gray-3 px-4 py-3  transition-colors",
        className
      )}
    />
  );
};

export default Input;
