import _ from "lodash";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { validateEmail } from "../../../utils/email";
import Button, { ButtonVariant } from "../../Button";
import Input from "../../Input";
import UIPill from "../../UIPill";
import TYFlower from "../../../assets/demo-page/thank-you-flower.svg";
import SomethingWrong from "../../../assets/demo-page/something-wrong.svg";
import Header, { HeaderVariant } from "../../Header";
import {
  useSegment,
  trackView,
  paidSocialEvent,
  HubspotEvent
} from "../../../utils/analytics";
import { chiliPiperSubmitHSForm } from "../../../utils/chilipiper";
import {
  Name,
  Type,
  Category,
  PaidSocialEvents,
  UTM,
  platformID
} from "../../../utils/analytics/constants";
import { Hubspot, Intercom } from "../../../constants/destinations";
import DropDownIcon from "../../../images/drop-down.svg";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountries,
  getCountryCallingCode
} from "react-phone-number-input";
import countryNamesEN from "react-phone-number-input/locale/en.json";
import "../DemoFormHubspot/demo.scss";
import classNames from "classnames";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import Select, { components, DropdownIndicatorProps } from "react-select";
import Chevron from "../assets/chevron.svg";
import SearchIcon from "../assets/search-icon.svg";

const DemoFormHubspot: React.FC<{}> = props => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const [showTYModal, setShowTYModal] = useState<boolean>(false);
  const [showSomethingWrongModal, setShowSomethingWrongModal] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const { segment } = useSegment();
  const [adSpendFilterOpen, setAdSpendFilterOpen] = useState<boolean>(false);
  const [locationFilterOpen, setLocationFilterOpen] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneInputClasses, setPhoneInputClasses] = useState<boolean>(false);
  const [location, setLocation] = useState<{}>(null);
  const [accountType, setAccountType] = useState<{
    title: string;
    value: string;
    selected: boolean;
  }>(null);

  const [platformList, setPlatformList] = useState<
    {
      title: string;
      value: string;
      selected: boolean;
    }[]
  >([]);

  const [adSpend, setAdSpend] = useState<{
    title?: string;
    selected?: boolean;
    hsID?: any;
  }>(null);

  const [AdSpendChild, setAdSpendChild] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [currentCountry, setCurrentCountry] = useState<{
    countryName?: string;
    countryCode?: string;
    countryCallingCode?: string;
  }>(null);

  const [inputError, setInputError] = useState<boolean>(false);

  const accountTypes = [
    { title: t("Brand"), value: "Brand", selected: false },
    { title: t("Agency"), value: "Agency", selected: false },
    { title: t("Seller"), value: "Seller", selected: false }
  ];

  const platforms = [
    { title: "Amazon", value: "Amazon", selected: false },
    { title: "Amazon DSP", value: "Amazon DSP", selected: false },
    { title: "Walmart", value: "Walmart", selected: false },
    {
      title: "Amazon Marketing Cloud (AMC)",
      value: "Walmart",
      selected: false
    },
    { title: "Instacart", value: "Instacart", selected: false },
    { title: t("Other"), value: "Other", selected: false }
  ];

  const adSpends = [
    {
      title: t("Not currently spending"),
      selected: false,
      hsID: 0
    },
    {
      title: t("Less than $50K"),
      selected: false,
      subAdSpend: [
        { title: t("$1 to $1K"), selected: false, hsID: 1 },
        { title: t("$1K to $5K"), selected: false, hsID: 2 },
        { title: t("$5K to $10K"), selected: false, hsID: 3 },
        { title: t("$10K to $50K"), selected: false, hsID: 4 }
      ]
    },
    {
      title: t("$50K to $100K"),
      selected: false,
      hsID: 5
    },
    {
      title: t("$100K to $200K"),
      selected: false,
      hsID: 6
    },
    {
      title: t("More than $200K"),
      selected: false,
      hsID: 7
    }
  ];

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => {
        let data = response.data;
        setCurrentCountry({
          countryName: data.country_name,
          countryCode: data.country_code,
          countryCallingCode: ""
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const cn = getCountries();

  const countries = cn
    .map((item, index) => {
      let country = {
        countryName: countryNamesEN[item],
        countryCode: item,
        countryCallingCode: "+" + getCountryCallingCode(item)
      };
      return country;
    })
    .sort((a, b) =>
      a.countryName.localeCompare(b.countryName, "en", {
        sensitivity: "base"
      })
    );

  const myCountry = countries.find(
    country => country.countryCode === currentCountry?.countryCode
  );
  const myCountryIndex = countries.findIndex(
    country => country.countryCode === currentCountry?.countryCode
  );
  const countriesWithMyCountry = [...countries];
  countriesWithMyCountry.splice(myCountryIndex, 1);
  if (myCountry !== undefined) {
    countriesWithMyCountry.unshift(myCountry);
  }

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setCompanyName("");
    setLocation(null);
    setAccountType(null);
    setPlatformList([]);
    setShowTYModal(true);
    setAdSpend(null);
  };

  const submitToHubSpot = () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      !validateEmail(email) ||
      companyName.length === 0 ||
      phoneNumber.length === 0 ||
      location.countryName === null ||
      accountType.title === null ||
      platformList.length === 0 ||
      adSpend.title === null
    ) {
      setInputError(true);
    } else {
      const portalId = Hubspot.portalId;
      const formGuid =
        language == "de" ? Hubspot.demoFormId_DE : Hubspot.demoFormId_EN;

      const postData = {
        portalId,
        formGuid,
        fields: [
          {
            name: "firstname",
            value: firstName
          },
          {
            name: "lastname",
            value: lastName
          },
          {
            name: "email",
            value: email
          },
          {
            name: "phone",
            value: phoneNumber
          },
          {
            name: "company",
            value: companyName
          },
          {
            name: "where_are_you_located_country_code_en",
            value: location?.countryName
          },
          {
            name: "perpetua_ad_spend_tier",
            value: adSpend?.hsID
          },
          {
            name: "seller_type",
            value: accountType.value
          },
          {
            name: "what_platform_s__are_you_interested_in_",
            value: platformList.map(e => e.value).join(";")
          },
          {
            name: "hs_language",
            value:
              language === "en-US"
                ? "en"
                : language === "zh-CN"
                ? "zh"
                : language
          },
          {
            name: "gclid",
            value:
              window?.localStorage?.getItem(platformID.gclid) != null
                ? window?.localStorage?.getItem(platformID.gclid)
                : ""
          },
          {
            name: "utm_campaign",
            value:
              window?.localStorage?.getItem(platformID.campaignid) != null
                ? window?.localStorage?.getItem(platformID.campaignid)
                : ""
          },
          {
            name: "fbclid",
            value:
              window?.localStorage?.getItem(platformID.fbclid) != null
                ? window?.localStorage?.getItem(platformID.fbclid)
                : ""
          },
          {
            name: "li_fat_id",
            value:
              window?.localStorage?.getItem(platformID.li_fat_id) != null
                ? window?.localStorage?.getItem(platformID.li_fat_id)
                : ""
          },
          {
            name: "utm_content",
            value:
              window?.localStorage?.getItem(UTM.content) != null
                ? window?.localStorage?.getItem(UTM.content)
                : ""
          },
          {
            name: "utm_medium",
            value:
              window?.localStorage?.getItem(UTM.medium) != null
                ? window?.localStorage?.getItem(UTM.medium)
                : ""
          },
          {
            name: "utm_source",
            value:
              window?.localStorage?.getItem(UTM.source) != null
                ? window?.localStorage?.getItem(UTM.source)
                : ""
          },
          {
            name: "utm_term",
            value:
              window?.localStorage?.getItem(UTM.term) != null
                ? window?.localStorage?.getItem(UTM.term)
                : ""
          },
          {
            name: "google_analytics_client_id",
            value:
              window?.localStorage?.getItem("google_analytics_client_id") !=
              null
                ? window?.localStorage?.getItem("google_analytics_client_id")
                : ""
          },
          {
            name: "partner_key",
            value:
              window?.localStorage?.getItem("partner_key") != null
                ? window?.localStorage?.getItem("partner_key")
                : ""
          },
          {
            name: "acquisition_channel_source",
            value:
              window?.localStorage?.getItem("acquisition_channel_source") !=
              null
                ? window?.localStorage?.getItem("acquisition_channel_source")
                : ""
          }
        ]
      };

      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
          postData,
          config
        )
        .then(res => {
          if (res.status == 200) {
            if (language !== "zh-CN") {
              // Send Segment event
              trackView(Category.FormInput, {
                category: "demo_signup",
                label: adSpend?.hsID
              });
              // Send Paid Social event
              paidSocialEvent("lintrk", PaidSocialEvents.DemoRequest.Linkedin.AllTiers);
              paidSocialEvent("fbq", PaidSocialEvents.DemoRequest.Facebook.AllTiers);
              if (adSpend?.hsID >= 2) {
                paidSocialEvent("lintrk", PaidSocialEvents.DemoRequest.Linkedin.P2PlusTiers);
                paidSocialEvent("fbq", PaidSocialEvents.DemoRequest.Facebook.P2PlusTiers);
              }

              HubspotEvent(email, Name.DemoSignup);

              chiliPiperSubmitHSForm({
                // Location hubspot field changed if chilipiper needs to change to keep consistency
                firstname: firstName,
                lastname: lastName,
                email: email,
                company: companyName,
                seller_type: accountType.title,
                where_are_you_located_country_code_en: location.countryName,
                perpetua_ad_spend_tier: adSpend?.hsID,
                what_platform_s__are_you_interested_in_: platformList
                  .map(e => e.title)
                  .join(";")
              });
            }

            clearForm();
            setLoading(false);
          }
        })
        .catch(err => {
          if (err.response.status != 200) {
            setShowSomethingWrongModal(true);
          }
        });
    }
  };

  const ThankYouModal = (
    <div className="flex flex-col justify-center w-full">
      <Header
        variant={HeaderVariant.h2}
        className="text-5xl lg:text-7xl font-semibold text-center md:font-bold"
      >
        {t("Thank you!")}
      </Header>
      <span className="mx-auto pb-10">
        <TYFlower />
      </span>
      <Header
        variant={HeaderVariant.h5}
        className="font-semibold text-center md:font-bold pb-12"
      >
        {t("We'll be in touch very soon!")}
      </Header>
      <Button
        text={t("Resubmit")}
        variant={ButtonVariant.primary}
        className="mx-auto w-[60%]"
        onClick={() => setShowTYModal(false)}
      />
    </div>
  );

  const styles = {
    menuList: styles => ({
      ...styles
      // background: "papayawhip"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "rgba(99, 102, 241)" : "inherit",
      "&:hover": {
        backgroundColor: state.isSelected ? "rgba(99, 102, 241)" : "#f1f5f9"
      },
      fontSize: 14,
      fontWeight: "600",
      paddingLeft: "1.5rem"
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      flexDirection: "row-reverse",
      border: state.isFocused
        ? "2px solid rgba(99, 102, 241,1)"
        : "2px solid rgba(203, 213, 225,1)",
      boxShadow: state.isFocused
        ? "0 0 0 0px #fff,0 0 0 calc(4px + 0px) rgba(224, 231, 225,1),0 0 #0000"
        : "none",
      "&:hover": {
        border: "2px solid rgba(107, 114, 128,1)"
      }
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      marginLeft: "0.5rem"
    }),
    placeholder: defaultStyles => ({
      ...defaultStyles,
      color: "rgba(203, 213, 225,1) !important",
      fontSize: 14,
      fontWeight: "500"
      // paddingLeft: "0.5rem"
    })
  };

  const DropdownIndicator = (
    props: DropdownIndicatorProps<ColourOption, true>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    );
  };

  const SomethingWrongModal = (
    <div className="flex flex-col justify-center w-full">
      <Header
        variant={HeaderVariant.h2}
        className="text-5xl lg:text-7xl font-semibold text-center md:font-bold"
      >
        {t("Something's wrong!")}
      </Header>
      <span className="mx-auto pb-10">
        <SomethingWrong />
      </span>
      <Header
        variant={HeaderVariant.h5}
        className="font-semibold text-center md:font-bold pb-12"
      >
        For some reason we couldn't receive <br />
        your request. Please reach us <br />
        directly by clicking below.
      </Header>
      <Button
        text={"Chat with us"}
        variant={ButtonVariant.primary}
        className="mx-auto w-[60%]"
        onClick={() => {
          segment
            .track(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: "Chat with us",
              page_url: window.location.href,
              destination: Intercom.Open,
              location: "HS demo form"
            })
            .then(() => {
              setShowSomethingWrongModal(false);
              clearForm();
            });
        }}
      />
    </div>
  );
  return (
    <div className="relative bg-white rounded-lg shadow-lg lg:px-10 px-10 md:px-[60px] py-10 xl:max-w-[664px] w-full">
      {showTYModal ? (
        ThankYouModal
      ) : showSomethingWrongModal ? (
        SomethingWrongModal
      ) : (
        <>
          <h1 className="text-2xl font-semibold text-center pb-10">
            {t("We just need a bit of info first")}
          </h1>
          <div className="flex flex-col lg:flex-row md:flex-row pb-5">
            <div className="w-full lg:mr-6 md:mr-6 pb-5 lg:pb-0">
              <p className="text-sm font-semibold pb-2">{t("First Name")}</p>
              <Input
                required
                placeholder={t("First Name")}
                className={classNames(
                  "w-full h-10 border-2",
                  inputError && firstName.length <= 0 ? "input-error" : ""
                )}
                value={firstName}
                onChange={e => setFirstName(e?.target?.value)}
              />
            </div>
            <div className="w-full">
              <p className="text-sm font-semibold pb-2">{t("Last Name")}</p>
              <Input
                required
                placeholder={t("Last Name")}
                className={classNames(
                  "w-full h-10 border-2",
                  inputError && lastName.length <= 0 ? "input-error" : ""
                )}
                value={lastName}
                onChange={e => setLastName(e?.target?.value)}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row md:flex-row pb-5">
            <div className="w-full lg:mr-6 md:mr-6 pb-5 lg:pb-0">
              <p className="text-sm font-semibold pb-2">{t("Work Email")}</p>
              <Input
                required
                placeholder={t("Work Email")}
                className={classNames(
                  "w-full h-10 border-2",
                  inputError && !validateEmail(email) ? "input-error" : ""
                )}
                value={email}
                onChange={e => setEmail(e?.target?.value)}
              />
            </div>
            <div className="w-full">
              <p className="text-sm font-semibold pb-2">{t("Phone")}</p>
              <PhoneInput
                initialValueFormat="national"
                defaultCountry={
                  currentCountry ? currentCountry.countryCode : "US"
                }
                placeholder="(087) 555 5658"
                className={classNames(
                  "bg-white rounded-lg border-gray-3-light placeholder-gray-3 px-4 py-3  transition-colors w-full h-10 border-2",
                  inputError &&
                    (phoneNumber == undefined || phoneNumber.length <= 0)
                    ? "input-error"
                    : "",
                  phoneInputClasses
                    ? "border-indigo-1 outline-none ring-4 ring-indigo-3-light"
                    : ""
                )}
                value={phoneNumber}
                onFocus={() => {
                  setPhoneInputClasses(true);
                }}
                onChange={setPhoneNumber}
              />
            </div>
          </div>
          <div className="w-full pb-5">
            <p className="text-sm font-semibold pb-2">{t("Company Name")}</p>
            <Input
              required
              placeholder={t("Company Name")}
              className={classNames(
                "w-full h-10 border-2",
                inputError && companyName.length <= 0 ? "input-error" : ""
              )}
              value={companyName}
              onChange={e => setCompanyName(e?.target?.value)}
            />
          </div>
          <div className="w-full pb-5">
            <p className="text-sm font-semibold pb-2 max-w-[75%]">
              {t("Where are you located?")}
            </p>
            <div className="w-full">
              <Select
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null
                }}
                className={classNames(
                  "country-selector",
                  inputError && location == null ? "input-error" : ""
                )}
                placeholder={t("Search")}
                defaultValue={countriesWithMyCountry[0]?.countryName}
                options={countriesWithMyCountry}
                getOptionLabel={option => option.countryName}
                getOptionValue={option => option.countryCode}
                onChange={e => {
                  setLocation(e);
                }}
                styles={styles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 8,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(203, 213, 225,1)"
                  }
                })}
              />
            </div>
          </div>
          <div className="w-full pb-5">
            <p className="text-sm font-semibold pb-2">
              {t("What best describes you?")}
            </p>
            <div className="flex flex-wrap">
              {accountTypes.map((key, index) => {
                return (
                  <UIPill
                    title={key.title}
                    touching
                    className={classNames(
                      index == 0
                        ? "rounded-l-lg rounded-r-none md:mx-0"
                        : index == 1
                        ? "border-y-2 rounded-none"
                        : index === 2
                        ? "rounded-r-lg md:mx-0 rounded-l-none"
                        : "",
                      inputError && accountType == null ? "input-error" : ""
                    )}
                    onClick={() => {
                      if (accountType !== key) {
                        setAccountType(key);
                      }
                    }}
                    selected={accountType?.title === key.title}
                  />
                );
              })}
            </div>
          </div>
          <div className="w-full pb-10">
            <p className="text-sm font-semibold pb-2">
              {t("What platform(s) are you interested in?")}
            </p>
            <div className="flex flex-wrap">
              {platforms?.map(key => {
                return (
                  <>
                    <UIPill
                      title={key.title}
                      className={
                        inputError && platformList.length === 0
                          ? "input-error"
                          : ""
                      }
                      onClick={() => {
                        const clonePlatformList = [...platformList];

                        if (
                          clonePlatformList.filter(e => e.title === key.title)
                            .length > 0
                        ) {
                          // if exists, remove
                          console.log(_.findIndex(clonePlatformList, key));
                          clonePlatformList.splice(
                            _.findIndex(clonePlatformList, key),
                            1
                          );
                          setPlatformList(clonePlatformList);
                        } else {
                          // if does not exist, add
                          clonePlatformList.push(key);
                          setPlatformList(clonePlatformList);
                        }
                      }}
                      selected={
                        platformList.filter(e => e.title === key.title).length >
                        0
                      }
                    />
                  </>
                );
              })}
            </div>
          </div>
          <div className="w-full pb-10">
            <p className="text-sm font-semibold pb-2">
              {t("What is your monthly ad spend?")}
            </p>
            <Button
              onClick={() => {
                setAdSpendFilterOpen(!adSpendFilterOpen);
                if (locationFilterOpen) {
                  setLocationFilterOpen(false);
                }
              }}
              className={classNames(
                "bg-white border-2 flex justify-between rounded-lg border-gray-3-light hover:border-gray-1 focus:border-indigo-1 focus:outline-none focus:ring-4 focus:ring-indigo-3-light placeholder-gray-3 px-4 py-3  transition-colors w-full h-10",
                inputError && adSpend == null ? "input-error" : ""
              )}
            >
              <div>
                <span className="text-gray-3 mr-4">{t("$")}</span>
                {adSpend ? (
                  <span>{adSpend?.title}</span>
                ) : (
                  <span className="text-gray-3">
                    <Trans i18nKey="select">- Select -</Trans>
                  </span>
                )}
              </div>
              <DropDownIcon />
              {adSpendFilterOpen === true && (
                <ul className="z-10 absolute top-full mt-1 py-2 left-0 bg-white overflow-hidden w-full rounded-md shadow-2xl">
                  {adSpends?.map((key, index) => (
                    <>
                      <li
                        key={index}
                        onClick={e => {
                          if (key?.subAdSpend) {
                            e.stopPropagation();
                            setAdSpendChild(true);
                          } else {
                            setAdSpend({ title: key.title, hsID: key.hsID });
                          }
                        }}
                        className="w-full flex items-center justify-between realative text-left px-6 py-2 text-base hover:bg-gray-5-light"
                      >
                        {key.title}
                        {index == 1 ? (
                          <Chevron className="ml-1 fill-current w-2" />
                        ) : (
                          ""
                        )}
                      </li>
                      {key?.subAdSpend && AdSpendChild ? (
                        <ul className="z-10 absolute mt-1 py-2 left-0 bg-white overflow-hidden w-full rounded-md shadow-2xl top-[0px]">
                          <li
                            className="w-full flex items-center text-left px-6 py-2 text-base hover:bg-gray-5-light"
                            onClick={e => {
                              e.stopPropagation();
                              setAdSpendChild(false);
                            }}
                          >
                            <Chevron className="mr-[10px] rotate-90 fill-current w-2" />
                            {t("Return")}
                          </li>
                          <hr className="text-gray-3 mx-6 my-2" />
                          {key?.subAdSpend?.map(key => {
                            return (
                              <li
                                className="w-full text-left px-6 py-2 text-base hover:bg-gray-5-light"
                                onClick={e => {
                                  setAdSpend({
                                    title: key.title,
                                    hsID: key.hsID
                                  });
                                  setAdSpendChild(false);
                                }}
                              >
                                {key.title}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                      {index == 0 ? (
                        <hr className="text-gray-3 mx-6 my-2" />
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </ul>
              )}
            </Button>
          </div>
          <p className="pb-10 text-left text-base text-gray-1">
            <Trans i18nKey="terms-and-conditions">
              By submitting this form you agree to our
              <a
                className={classNames("underline", language !== "ja" && "mx-1")}
                href={t("https://perpetua.io/tos/")}
              >
                terms and conditions
              </a>
              and
              <a className="underline mx-1" href="https://perpetua.io/privacy/">
                privacy policy
              </a>
              as well as to receive marketing emails from Perpetua. You can
              unsubscribe from these communications at any time.
            </Trans>
          </p>
          <Button
            text={!loading ? t("Schedule a Demo") : "Loading..."}
            variant={ButtonVariant.primary}
            className={`mx-auto w-[60%] ${
              loading && "cursor-progress disabled"
            }`}
            onClick={() => !loading && submitToHubSpot()}
          />
          {inputError ? (
            <p className="text-center mt-8 err-msg font-medium">
              {t("Please make sure all inputs are filled in and valid.")}
            </p>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default DemoFormHubspot;
